<template>
  <footer class="mt-12 text-sm sm:text-base">
    <!-- Newsletter -->
    <div class="bg-gray-10 pb-20 pt-14">
      <Container
        class="flex flex-col space-y-8 md:flex-row md:space-x-16 md:space-y-0 lg:space-x-20 2xl:flex-wrap"
      >
        <div class="grow-0">
          <Logo class="h-9" />
        </div>

        <div v-if="general" class="flex grow flex-wrap">
          <div class="w-full xl:w-1/2">
            <div class="flex">
              <div class="flex flex-col">
                <address v-if="general.address" class="flex-1 not-italic">
                  <div class="[&>p:first]:mb-4" v-html="general.address" />
                </address>

                <div v-if="general.phone" class="mt-4 flex items-start">
                  <IconPhone class="mr-2.5 mt-1.5 w-4" />
                  <span class="font-bold">{{ general.phone }}</span>
                </div>
                <div v-if="general.email" class="mt-1 flex items-start">
                  <IconMail class="mr-2.5 mt-1.5 w-4" />
                  <span class="font-bold">{{ general.email }}</span>
                </div>
              </div>

              <div class="ml-6 flex flex-col sm:ml-10 lg:ml-16">
                <address v-if="general.support_text" class="flex-1 not-italic">
                  <div class="[&>p:first]:mb-4" v-html="general.support_text" />
                </address>

                <div v-if="general.support_phone" class="mt-4 flex items-start">
                  <IconPhone class="mr-2.5 mt-1.5 w-4" />
                  <span class="font-bold">{{ general.support_phone }}</span>
                </div>
                <div v-if="general.support_email" class="mt-1 flex items-start">
                  <IconMail class="mr-2.5 mt-1.5 w-4" />
                  <span class="font-bold">{{ general.support_email }}</span>
                </div>
              </div>
            </div>
          </div>

          <NewsletterSignUp
            class="mt-12 w-full max-w-xl xl:mt-0 xl:w-1/2 xl:max-w-[30rem] xl:pl-8"
          />
        </div>
      </Container>
    </div>

    <!-- Main Mobile -->
    <div class="bg-black pb-20 pt-8 text-white md:hidden lg:pb-32 lg:pt-12">
      <Container class="space-y-5">
        <FooterAccordion
          v-for="(item, index) in footerLinks"
          :key="index"
          :data="item"
        />
      </Container>
    </div>

    <!-- Main Desktop -->
    <div class="hidden bg-black pb-32 pt-12 text-white md:block">
      <Container class="flex flex-wrap bg-black text-white">
        <ul
          v-for="(item, index) in footerLinks"
          :key="index"
          class="w-full space-y-2 p-4 text-sm sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5"
        >
          <li class="mb-4 text-xs font-bold uppercase tracking-widest">
            <NuxtLink :to="item.page.url">{{ item.page.title }}</NuxtLink>
          </li>
          <li v-for="(child, level2Index) in item.children" :key="level2Index">
            <NuxtLink :to="child.page.url">{{ child.page.title }}</NuxtLink>
          </li>
        </ul>
      </Container>
    </div>

    <!-- Meta -->
    <div class="py-7">
      <Container>
        <div
          class="flex items-start justify-between max-lg:flex-col max-lg:gap-8 lg:items-center"
        >
          <span class="block text-xs font-semibold">
            © Jean-Pierre Rosselet Cosmetics {{ currentYear }}
          </span>

          <PaymentProviderIcons />

          <div>
            <img src="/img/bbs.png" class="h-5" loading="lazy" />
          </div>
        </div>
      </Container>
    </div>
  </footer>
</template>

<script setup>
const cms = inject(CMS_GLOBALS)
const currentYear = new Date().getFullYear()
const general = computed(() => cms?.value?.general)
const footerLinks = computed(() => cms?.value?.footer?.tree)
</script>
